<script lang="ts">
	import Layout from "../components/Layout.svelte";
	import HomepageBanner from "../components/HomepageBanner.svelte";
	import Ways from "../components/Ways.svelte";
	import HowItWorks from "../components/HowItWorks.svelte";
	import WeddingsBanner from "../components/Wedding/WeddingsBanner.svelte";
	import type { HomepageProps } from "./HomepageProps";
	import Branches from "../components/Branches.svelte";
	import RelatedProducts from "../components/Product/RelatedProducts.svelte";
	import VouchersBanner from "../components/Voucher/VouchersBanner.svelte";
	import B2bBanner from "../components/B2bBanner.svelte";
	import { getGlobalSettings } from "../getGlobalSettings";
	import HomepageBannerXmas from "../components/HomepageBannerXmas.svelte";
	import { getContext } from "svelte";
	import { requiredCategoriesContextKey } from "../contexts/requiredCategoriesContextKey.js";
	import type { RequiredCategories } from "../../../core/schema/namesOfRequiredCategories.js";
	import HomepageBannerValentines from "../components/HomepageBannerValentines.svelte";
	import WarehouseProducts from "../components/Product/WarehouseProducts.svelte";

	type $$Props = HomepageProps;

	const { products, maxProductsPerCategory, warehouseProducts } = $$restProps as $$Props;

	const { FF_B2B, FF_SHOWN_PROMO } = getGlobalSettings();
	const requiredCategories = getContext<RequiredCategories>(requiredCategoriesContextKey);
</script>

<Layout>
	<div class="container">
		{#if FF_SHOWN_PROMO === "xmas"}
			<HomepageBannerXmas />
		{:else if FF_SHOWN_PROMO === "valentines"}
			<HomepageBannerValentines />
		{:else}
			<HomepageBanner />
		{/if}

		<section>
			<Ways {warehouseProducts} />
		</section>

		<section>
			<HowItWorks />
		</section>

		{#if warehouseProducts.length > 0}
			<section>
				<WarehouseProducts {maxProductsPerCategory} {warehouseProducts} />
			</section>
		{/if}

		<section>
			<RelatedProducts {products} {maxProductsPerCategory} category={requiredCategories.cakes} />
		</section>

		<section>
			<WeddingsBanner border />
		</section>
		{#if FF_B2B === "true"}
			<section>
				<B2bBanner />
			</section>
		{/if}

		<section>
			<VouchersBanner border />
		</section>

		<section>
			<Branches />
		</section>
	</div>
</Layout>
