<script lang="ts">
	import type { ProductForCustomer } from "../../../../core/schema/Product";
	import Link from "../Link.svelte";
	import ProductCard from "./ProductCard.svelte";
	import { getGlobalSettings } from "../../getGlobalSettings.js";
	import type { ProductAmountPerCategory } from "../../../../core/schema/ProductAmountPerCategory";
	import { getWarehouseProductCategoryPath } from "../../../../core/schema/paths/getWarehouseProductCategoryPath";

	export let maxProductsPerCategory: ProductAmountPerCategory;
	export let warehouseProducts: ProductForCustomer[];

	const { TENANT_LOCALE } = getGlobalSettings();
</script>

<div>
	<h2 class="decorative-headline">Expresní</h2>
	<div class="product-card-wrapper mt-6 lg:mt-2">
		{#each warehouseProducts as product, index (product.id)}
			<ProductCard {product} {index} {maxProductsPerCategory} isWarehouseProduct />
		{/each}
	</div>
	<Link
		class="mx-auto mt-10 text-center sm:!px-14"
		href="/{getWarehouseProductCategoryPath(TENANT_LOCALE, 'cakes')}"
		asButton
		variant="secondary"
	>
		<div>Zobrazit další produkty</div>
	</Link>
</div>
