<script lang="ts">
	import Link from "./Link.svelte";
	import { weddingsPathMap } from "../../../core/schema/paths/weddingsPathMap.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import { categoryPathMap } from "../../../core/schema/paths/categoryPathMap.js";
	import { getWarehouseProductCategoryPath } from "../../../core/schema/paths/getWarehouseProductCategoryPath";
	import type { ProductForCustomer } from "../../../core/schema/Product";

	export let warehouseProducts: ProductForCustomer[];

	const { TENANT_LOCALE, FF_SHOW_XMAS_IN_MENU, FF_SHOW_VALENTINES_IN_MENU } = getGlobalSettings();

	interface Way {
		name: string;
		urlSlug: string;
	}

	const ways: Way[] = [
		{
			name: "Dorty z vitríny",
			urlSlug: `/${categoryPathMap.cakes[TENANT_LOCALE]}`,
		},
		{
			name: "Zákusky",
			urlSlug: `/${categoryPathMap.desserts[TENANT_LOCALE]}`,
		},
		{
			name: "Minidezerty",
			urlSlug: `/${categoryPathMap.minidesserts[TENANT_LOCALE]}`,
		},
		{
			name: "Svatební dorty",
			urlSlug: `/${weddingsPathMap[TENANT_LOCALE]}`,
		},
	];

	if (FF_SHOW_XMAS_IN_MENU === "true") {
		ways.unshift({
			name: "Vánoční cukroví",
			urlSlug: `/${categoryPathMap.christmas[TENANT_LOCALE]}`,
		});
	}

	if (FF_SHOW_VALENTINES_IN_MENU === "true") {
		ways.unshift({
			name: "Valentýn",
			urlSlug: `/${categoryPathMap.valentines[TENANT_LOCALE]}`,
		});
	}

	if (warehouseProducts.length > 0) {
		ways.push({
			name: "Expresní",
			urlSlug: `/${getWarehouseProductCategoryPath(TENANT_LOCALE, "cakes")}`,
		});
	}
</script>

<div>
	<h2 class="decorative-headline">Naše dobroty</h2>
	<ul class="flex flex-wrap justify-center gap-x-4 gap-y-0">
		{#each ways as way, index}
			<li>
				<Link
					href={way.urlSlug}
					class="hover:border-secondary-250 group flex items-center gap-2 rounded-2xl border border-transparent px-3 py-2 font-semibold no-underline transition-colors duration-300 sm:gap-3 sm:px-4 sm:py-3 xl:my-2 xl:gap-6 xl:rounded-3xl xl:px-6 xl:py-3"
				>
					<div
						class="xl:text-md group-hover:text-primary flex h-5 w-5 items-center justify-center rounded-full border border-black text-[.9rem] transition-colors duration-300 sm:h-6 sm:w-6 sm:text-base xl:h-7 xl:w-7"
					>
						{index + 1}
					</div>
					<span
						class="group-hover:text-primary group text-[2rem] underline decoration-transparent decoration-2 underline-offset-8 transition-colors duration-300 sm:text-[2.5rem] xl:text-[3.5rem]"
					>
						{way.name}
					</span>
				</Link>
			</li>
		{/each}
	</ul>
</div>
